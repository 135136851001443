import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISellerOnboardResponse } from './seller-onboard.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SellerOnboardSharedService {

  private dataSubject = new BehaviorSubject<ISellerOnboardResponse>({} as ISellerOnboardResponse);

  public sellerInfoObservable = this.dataSubject.asObservable();

  private formDataSubject = new BehaviorSubject<FormGroup>({} as FormGroup);

  public formDataObservable = this.formDataSubject.asObservable();

  setSellerOnboardData(data: ISellerOnboardResponse) {
    this.dataSubject.next(data);
  }

  getSellerOnboardData() {
    // return this.dataSubject.asObservable();
    return this.dataSubject.getValue();
  }

  constructor() { }






  updateFormData(formData: FormGroup) {
    this.formDataSubject.next(formData);
  }
}
