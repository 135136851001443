<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content text-center">
    <div class="modal-body p-4">
      <div class="success-img">
        <img [src]="imageUrl" alt="success">
      </div>
      <div class="approval-message-content">
        <h4 class="pb-3 m-0">
          {{messageHeader}}
        </h4>
        <p class="m-0">
          {{messageContent}}
        </p>
      </div>
      <div class="d-grid">
        <button class="btn button-green p-2" (click)="closeModal()" type="button">
          {{buttonText}}
        </button>
      </div>
    </div>
  </div>
</div>