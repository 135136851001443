import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RestApiService, ServiceType } from './api.service';
import { HttpClientService } from './http-client.service';
import { FileHandle } from '../directives/drop-zone/drop-zone.directive';

export enum UploadStatues {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  YET_TO_START = 'YET_TO_START',
  FAILED = 'FAILED',
}

export enum Unit {
  Milliliter = 'ml',
  Gram = 'g',
  Liter = 'L',
  Nos = 'Nos',
  Kilogram = 'kg',
  Milligram = 'mg'
}
export interface MenuCategoriesType {
  id: number;
  name: string;
  value: string;
}
export const MENU_CATEGORIES: Array<MenuCategoriesType> = [{
  id: 1,
  name: 'Break fast',
  value: 'breakfast',
}, {
  id: 2,
  name: 'Lunch',
  value: 'lunch',
}, {
  id: 3,
  name: 'Snacks',
  value: 'snacks',
}, {
  id: 4,
  name: 'Dinner',
  value: 'dinner',
}];
export interface IS3FileResponse {
  url: string;
  expiresIn: number;
}
export enum FoodType {
  PURE_VEG = 'pureVeg',
  VEG = 'veg',
  NON_VEG = 'nonVeg',
  VEG_NON_VEG = 'vegNonVeg',
  VEGAN = 'vegan',
}
export interface ICuisine {
  cuisineId: string;
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
  __v?: number;
  _id?: string;
}
export interface IPreferredMealTime {
  id: number,
  name: string,
  value: string;
}
export interface IMenuItem {
  title: string;
  description: string;
  itemImageS3FileName?: string;
  itemImageOriginalFileName?: string;
  itemImageUrl?: IS3FileResponse;
  itemImageArrayBuffer?: ArrayBuffer;
  itemCategory: string;
  price: number;
  itemDetails?: string;
  masterQuantity: number;
  foodType: FoodType;
  kitchenId: string;
  itemId: string;
  active?: boolean;
  cuisineCategory?: ICuisine[];
  preferredMealTime?: IPreferredMealTime[];
  displayPrice?: number;
  serves: number;
  preparationTime?: number;
  isBulkPreparable?: boolean;
  isPreBookingItem?: boolean;
  isGetItImmediatelyItem?: boolean;
  deliveryTimeOfGetItImmediatelyItem?: number;
  approvalStatus?: string;
  foodImage?: FileHandle;
  tags?: string[];
  isChefSpecial?: boolean;
  isRestricted?: boolean;
  restrictedReason?: string;
  menuItems?: Array<IItemMeasurement>;
  ingredients?: Array<string>;
}

export interface IItemMeasurement {
  itemName: string;
  quantity: number;
  uom: string;
}

export interface ICreateMenuItem {
  title: string;
  menuItems: Array<IItemMeasurement>;
  ingredients: Array<string>;
  description: string;
  itemImageS3FileName?: string;
  itemImageOriginalFileName?: string;
  itemCategory: string;
  price: number;
  itemDetails?: string;
  masterQuantity: number;
  foodType: FoodType;
  kitchenId: string;
  active: boolean;
  cuisineCategory: ICuisine[];
  preferredMealTime?: IPreferredMealTime[];
  displayPrice?: number;
  serves?: number;
  preparationTime?: number;
  isBulkPreparable?: boolean;
  isPreBookingItem?: boolean;
  isGetItImmediatelyItem?: boolean;
  deliveryTimeOfGetItImmediatelyItem?: number;
}

export enum MenuItemType {
  FOOD_ITEMS = 'foodItems',
  PRODUCTS = 'products',
}

interface IBulkUploadItemDetails {
  itemDetails: Array<ICreateMenuItem & {
    itemId?: string;
  } & {
    id: string,
    image: File;
    isEdited?: boolean;
    uploadStatus: UploadStatues,
    errors: string[];
  }>;
}

export interface IUpdateMenuItem extends Partial<ICreateMenuItem> { }

@Injectable()
export class MenuItemApiService extends RestApiService {
  private readonly headers: any;

  protected override baseApiUrl = environment.config['SELLER_PORTAL_SERVICE_URI'];

  private idToken = localStorage.getItem('idToken') || '';
  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/items`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
    this.headers = {
      ['x-auth-provider']: 'google',
      ['user-current-view']: 'cook',
      idtoken: this.idToken,
    };
  }

  async get(id: string): Promise<{ data: IMenuItem; }> {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpGet(apiPath, this.headers);
  }

  async getAll(kitchenId: string): Promise<{ data: IMenuItem[]; }> {
    const apiPath = `${this.servicePath}/kitchen/${kitchenId}`;
    return this.httpGet(apiPath, this.headers);
  }

  async create(payload: ICreateMenuItem): Promise<IMenuItem> {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpDelete(apiPath, this.headers);
  }

  async update(id: string, payload: IUpdateMenuItem) {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpPut(apiPath, payload, this.headers);
  }
}
