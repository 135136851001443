import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { RestApiService } from './api.service';
import { HttpClientService } from './http-client.service';

export interface ICuisine {
  cuisineId: string;
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
}

export interface createCuisine {
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
}

@Injectable()
export class CuisineApiService extends RestApiService {
  private readonly headers: any;
  // TODO: update this end point
  protected override baseApiUrl = environment.config['SELLER_PORTAL_SERVICE_URI'];

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/items/cuisine/cuisine-details/`;

  private idToken = localStorage.getItem('idToken') || ''

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
    this.headers = {
      ['x-auth-provider']: 'google',
      ['user-current-view']: 'cook',
      idtoken: this.idToken,
    }
  }

  async get(id: string): Promise<{data:ICuisine}> {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpGet(apiPath, this.headers);
  }

  async getAll(): Promise<{ data: ICuisine[]}> {
    const apiPath = `${this.servicePath}`;
    return this.httpGet(apiPath, this.headers);
  }

  async create(payload: createCuisine) {
    const apiPath = `${this.servicePath}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpDelete(apiPath, this.headers);
  }

  async update(id: string, payload: Partial<createCuisine>) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpPut(apiPath, payload, this.headers);
  }
}
