import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SellerOnboardSharedService } from '../services/seller-onboard-shared.service';
import { IBankTaxDetails, ISellerOnboardResponse, ScreeningStatus, SellerOnboardApiService, SellerStatus } from '../services/seller-onboard.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ProfileApprovalStatusEnum } from 'src/constants/approval-log.contants';

const onboardingTabStatus = {
  default: 'NOT STARTED',
  notStarted: 'NOT STARTED',
  inProgress: 'IN PROGRESS',
  completed: 'COMPLETED'
}

enum onboardingSteps {
  PRESCREENING = 1,
  PERSONAL_DETAILS = 2,
  FSSAI_DETAILS = 3,
  BANK_DETAILS = 4,
  VISUAL_VERIFICATION = 5,
  KITCHEN_ONBOARDING = 6
}

const onboardingTabs: Array<{
  step: number,
  objKeyName: keyof ISellerOnboardResponse,
  tabName: string,
  route: string,
  status: string
}> = [
    {
      step: onboardingSteps.PRESCREENING,
      objKeyName: 'preScreening',
      tabName: 'Pre-screening',
      route: 'pre-screening',
      status: onboardingTabStatus.default,
    },
    {
      step: onboardingSteps.PERSONAL_DETAILS,
      objKeyName: 'personalInfo',
      tabName: 'Personal details',
      route: 'personal-details',
      status: onboardingTabStatus.default,
    },
    {
      step: onboardingSteps.FSSAI_DETAILS,
      objKeyName: 'fssaiAndTermsConditions',
      tabName: 'FSSAI, T&C',
      route: 'fssai-details',
      status: onboardingTabStatus.default,
    },
    {
      step: onboardingSteps.BANK_DETAILS,
      objKeyName: 'bankDetailsAndTax',
      tabName: 'Tax & Bank Details',
      route: 'bank-details',
      status: onboardingTabStatus.default,
    },
    {
      step: onboardingSteps.VISUAL_VERIFICATION,
      objKeyName: 'inspectionInitiation',
      tabName: 'Visual Verification',
      route: 'kitchen-inspection',
      status: onboardingTabStatus.default,
    },
    {
      step: onboardingSteps.KITCHEN_ONBOARDING,
      objKeyName: 'profile',
      tabName: 'Onboarding',
      route: 'kitchen-onboarding',
      status: onboardingTabStatus.default,
    },
  ];

@Injectable({
  providedIn: 'root',
})

export class SellerOnboarAuthGuard implements CanActivate {

  public currentStep = 1;

  public sellerInfo!: ISellerOnboardResponse;

  public onboardingTabs = onboardingTabs;

  public sellerStatus = SellerStatus;

  constructor(
    private router: Router,
    private sellerOnboardService: SellerOnboardApiService,
    private sellerOnboardSharedService: SellerOnboardSharedService,
    private toast: HotToastService
  ) { }

  isAllBankDetailsExist() {
    const bankDetails = this.sellerInfo?.bankDetailsAndTax?.bankDetails;
    return bankDetails?.accountHolderName && bankDetails?.accountNumber && bankDetails?.ifscCode;
  }

  private async fetchSellerInfoById(sellerInfoId: string) {
    try {
      let sellerInfo = this.sellerOnboardSharedService.getSellerOnboardData();
      if (sellerInfo.hasOwnProperty('sellerInfoId') && sellerInfoId === sellerInfo.sellerInfoId) {
        return sellerInfo;
      }
      const response = await this.sellerOnboardService.getSellerInfoById(sellerInfoId);
      sellerInfo = response?.data;
      if (sellerInfo?.sellerInfoId) {
        this.sellerOnboardSharedService.setSellerOnboardData(sellerInfo);
        return sellerInfo;
      } else {
        localStorage.clear();
        this.router.navigate(['/']);
        return null;
      }
    } catch (error) {
      console.log('seller-onboard-auth.guard.ts: fetchSellerInfoById() - Unable to fetch the seller info!', error);
      return null;
    }
  }

  private navigateToPersonalDetails(url: string): boolean {
    if (url.endsWith('personal-details')) {
      return true;
    }
    this.router.navigate(['/seller-onboard/personal-details']);
    return false;
  }

  private handlePrescreeningStep(url: string): boolean {
    /* 
    prescreening obj    parentZoneId
        0                   0       ====> During registeration, user selected other parent zone, then navigate to unserviceable page
        1                   0       ====> drafted, allow to pre-screening page
        0                   1       ====> allow to pre-screening page
        1                   1       ====> drafted, allow to pre-screening page
    */
    if (!this.sellerInfo.preScreening && !this.sellerInfo.parentZoneId) {
      if (url.endsWith('unserviceable-area')) {
        return true;
      }
      this.router.navigate(['seller-onboard/unserviceable-area']);
      return false;
    } else {
      if (url.endsWith('pre-screening')) {
        return true;
      }
      this.router.navigate(['/seller-onboard/pre-screening']);
      return false;
    }
  }

  private handleScreeningSteps(url: string, requestedStep: number, lastEligibleStep: number): boolean {
    // When user can go till personal details
    if (lastEligibleStep === 2) {
      // but the selected location is outside our zones
      if (this.sellerInfo?.personalInfo && this.sellerInfo.personalInfo?.isInOurZone === false && !this.sellerInfo.personalInfo.isDrafted) {
        if (url.endsWith('unserviceable-area')) {
          return true
        }
        this.router.navigate(['seller-onboard/unserviceable-area']);
        return false;
      }
      // the selected location is within our zones, then let the user navigate to the personal-details page
      return this.navigateToPersonalDetails(url);
    }
    else if (requestedStep > 1 && requestedStep <= lastEligibleStep) {
      // When user can go till visual verification page
      if (lastEligibleStep === 5) {
        // and also the user is eligible to proceed to the onboarding page, but has any incomplete or inprogress step(s)
        if (this.sellerInfo.sellerStatus === this.sellerStatus.INITIATE_ONBOARDING) {
          // user has an incomplete step - yet to submit to the personal details, then navigate to the personal-details page
          if ((requestedStep === 2 || requestedStep === 5) && this.sellerInfo.personalInfo.isDrafted) {
            return this.navigateToPersonalDetails(url);
          }
          // user has an incomplete step - yet to submit to the fssai, then navigate to the fssai-details page
          else if ((requestedStep === 3 || requestedStep === 5) && (!this.sellerInfo.preScreening.fssaiNumber || this.sellerInfo.fssaiAndTermsConditions.isDrafted)) {
            if (url.endsWith('fssai-details')) {
              return true;
            }
            this.router.navigate(['/seller-onboard/fssai-details']);
            return false;
          }
          // user has an incomplete step - yet to provide the bank details, then navigate to the bank-details page
          else if ((requestedStep === 4 || requestedStep === 5) && (this.sellerInfo.bankDetailsAndTax.isProvideLater === true || this.sellerInfo.bankDetailsAndTax.isDrafted)) {
            if (url.endsWith('bank-details')) {
              return true;
            }
            this.router.navigate(['/seller-onboard/bank-details']);
            return false;
          } else {
            if (url.endsWith('kitchen-inspection')) {
              return true;
            } else {
              this.router.navigate(['/seller-onboard/kitchen-inspection']);
              return false;
            }
          }
        }
        // User submitted visual verfication & waiting to get onboarded, he can move to the requested step(i.e., between 2 and 5)
        return true;
      }
      return true;
    }
    else {
      this.router.navigate(['/seller-onboard', this.getRouteFromStepNumber(lastEligibleStep)]);
      return false;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let sellerInfoId = localStorage.getItem('sellerInfoId');

    if (!sellerInfoId) {
      // this.toast.warning('Seller ID not found!')
      this.router.navigate(['/']);
      return false;
    }

    this.sellerInfo = await this.fetchSellerInfoById(sellerInfoId) as ISellerOnboardResponse;
    if (!this.sellerInfo) {
      this.toast.warning('Unable to fetch the seller info or seller info is empty!')
      this.router.navigate(['/']);
      return false;
    }

    if (this.sellerInfo?.profileApprovalStatus === ProfileApprovalStatusEnum.APPROVED ||
      ('kitchenId' in this.sellerInfo && 'cookId' in this.sellerInfo)) {
      localStorage.setItem('kitchenId', this.sellerInfo.kitchenId || '');
      localStorage.setItem('cookId', this.sellerInfo.cookId || '');
      localStorage.setItem('showOnboardingInitiated', 'true');
      this.router.navigate(['/cook-profile']);
      return false;
    }

    // seller profile has been rejected
    if (this.sellerInfo.screeningStatus === ScreeningStatus.REJECTED) {
      const showProfileRejectionPage = state.url.endsWith('profile-rejection');
      if (showProfileRejectionPage) {
        return true;
      }
      this.router.navigate(['/seller-onboard/profile-rejection']);
      return false;
    }

    // seller profile is in waiting state
    if (this.sellerInfo.screeningStatus === ScreeningStatus.WAITING) {
      const showProfileWaitingPage = state.url.endsWith('profile-waiting');
      if (showProfileWaitingPage) {
        return true;
      }
      this.router.navigate(['/seller-onboard/profile-waiting']);
      return false;
    }

    const lastEligibleStep = this.getLastEligibleStep();
    console.log('seller-onboard-auth.guard.ts: canActivate() - current step:', lastEligibleStep)
    const requestedStep = this.getStepNumberFromRoute(state.url);
    console.log('seller-onboard-auth.guard.ts: canActivate() - requested step:', requestedStep)

    // User can to go till pre-screening & the requested step is also the same
    if (lastEligibleStep === onboardingSteps.PRESCREENING && lastEligibleStep === requestedStep) {
      return this.handlePrescreeningStep(state.url)
    }
    // Navigation between personal details & visual verification
    else if (lastEligibleStep > onboardingSteps.PRESCREENING && lastEligibleStep < onboardingSteps.KITCHEN_ONBOARDING) {
      return this.handleScreeningSteps(state.url, requestedStep, lastEligibleStep);
    }
    // User is eligible for kitchen onboarding page & the requested step is also the same
    else if (lastEligibleStep === onboardingSteps.KITCHEN_ONBOARDING && lastEligibleStep === requestedStep) {
      return true
    }
    // User completed all the onboarding steps & can go to cook profile page
    else if (lastEligibleStep === 7) {
      this.router.navigate(['cook-profile']);
      return false;
    }
    // If no decision can be made, navigate user to his last eligible step 
    else {
      this.router.navigate(['/seller-onboard', this.getRouteFromStepNumber(lastEligibleStep)]);
      return false;
    }
  }

  checkStepCompletion(onboardingTab: {
    step: number;
    objKeyName: keyof ISellerOnboardResponse;
    tabName: string;
    status: string;
  }) {
    return (
      (onboardingTab.objKeyName === 'preScreening' && !this.sellerInfo['sellerStatus']) ||
      (onboardingTab.objKeyName === 'personalInfo' && this.sellerInfo.personalInfo.isInOurZone === false) ||
      (onboardingTab.objKeyName === 'inspectionInitiation' && this.sellerInfo.sellerStatus !== SellerStatus.INITIATE_ONBOARDING)
    );
  }

  checkStepIncompletion(onboardingTab: {
    step: number;
    objKeyName: keyof ISellerOnboardResponse;
    tabName: string;
    status: string;
  }) {
    return (
      (onboardingTab.objKeyName === 'bankDetailsAndTax' && (this.sellerInfo.bankDetailsAndTax.isProvideLater === true || !this.isAllBankDetailsExist())) ||
      (onboardingTab.objKeyName === 'fssaiAndTermsConditions' && !this.sellerInfo.preScreening.fssaiNumber)
    );
  }

  private getLastEligibleStep(): number {
    let isInprogress = false;
    let draftedIndex = 0;
    let isInComplete = false;
    let showDraftedPage = false;
    for (let [index, onboardingTab] of this.onboardingTabs.entries()) {
      const onboardingstep = this.sellerInfo[onboardingTab.objKeyName];
      if (!onboardingstep) {
        if (index === 0 && !this.sellerInfo.parentZoneId) {
          return onboardingTab.step; // seller is not allowed to pre-screening page as he submitted other parent zone
        } else if (index === 5 && (showDraftedPage || isInComplete || isInprogress)) {
          return index; // seller has an incomplete or inprogress step
        }
        if (showDraftedPage) {
          return this.onboardingTabs[draftedIndex].step;
        }
        return onboardingTab.step
      }
      if (onboardingstep && typeof onboardingstep === 'object') {
        if ('isDrafted' in onboardingstep && onboardingstep.isDrafted === true) {
          isInprogress = true;
          draftedIndex = index;
          showDraftedPage = true;
          if (index === 5) return onboardingTab.step;
        } else if ('isDrafted' in onboardingstep && onboardingstep.isDrafted === false) {
          showDraftedPage = false;
          if (this.checkStepCompletion(onboardingTab)) {
            return onboardingTab.step;
          } else if (this.checkStepIncompletion(onboardingTab)) {
            isInComplete = true;
          } else if (onboardingTab.objKeyName === 'profile') {
            if (this.sellerInfo.profileApprovalStatus === 'approved') {
              localStorage.setItem('cookId', this.sellerInfo?.cookId!);
              localStorage.setItem('kitchenId', this.sellerInfo.kitchenId!);
              return 7
            }
            return onboardingTab.step;
          }
        }
      }
    }
    return 7;
  }

  private getStepNumberFromRoute(url: string): number {
    let urlPrefix = 'seller-onboard/';
    let match = url.substring(url.indexOf(urlPrefix) + urlPrefix.length);
    if (match) {
      const step = this.onboardingTabs.find(tab => tab.route.toLowerCase() === match.toLowerCase());
      return step ? step.step : 1;
    }
    return 1; // Default to the first step if the URL format is not recognized
  }

  private getRouteFromStepNumber(step: number): string {
    const matchingTab = this.onboardingTabs.find(tab => tab.step === step);
    return matchingTab ? matchingTab.route : 'pre-screening'; // Default to pre-screening if no matching step is found
  }
}
