import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpMethod } from '../enums/httpmethod.enum';
import { ModalService } from '../lib/modal/modal.service';
import { SiteUnderMaintenanceModalComponent } from '../app/shared/site-under-maintenance-modal/site-under-maintenance-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

interface IRefreshToken {
  statusCode: number;
  timestamp: string;
  method: string;
  path: string;
  message: string;
  data: {
    idToken: string;
    refreshToken: string;
  }
}

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {

  private isSiteUnderMaintenance = false;

  private refreshTimer!: any;

  protected adminBaseApiUrl = environment.config['SELLER_PORTAL_SERVICE_URI'];

  /**
   * Creates an instance of HttpClientService.
   * @param {HttpClient} http
   * @memberof HttpClientService
   */
  constructor(private http: HttpClient,
    private modalService: ModalService,
    private ngModalService: NgbModal,
    private readonly router: Router,
  ) { }

  /**
   * It calls the API and returns the response.
   * @param {string} path - The path to the API endpoint.
   * @param {HttpMethod} method - HttpMethod.GET,
   * @param {any} payload - The data that you want to send to the server.
   * @returns A promise.
   */
  async call(
    path: string,
    payload?: any,
    method: HttpMethod = HttpMethod.GET,
    additionalHeaders = {},
  ) {
    if (!this.isSiteUnderMaintenance) {
      const url = `${path}`; // TODO: prepend base api url
      const headers: any = {
        'Content-Type': 'application/json',
      };

      const allHeaders = {
        ...headers,
        ...additionalHeaders,
      };
      const httpHeaders = new HttpHeaders(allHeaders);

      const options = {
        headers: httpHeaders,
        body: payload,
      };

      try {
        const response = await firstValueFrom(this.http.request(method, url, options));
        clearInterval(this.refreshTimer);
        return response;
      } catch (error: HttpErrorResponse | any) {
        const { errorCode } = error.error;
        if (errorCode === 'GENERIC406') {
          this.showSiteUnderMaintenanceModal();
        }
        if (errorCode === 'SP403105') {
          const payload = {
            refreshToken: localStorage.getItem('refreshToken')
          }
          this.call(`${this.adminBaseApiUrl}/gcp-store/refreshToken`, payload, HttpMethod.POST).then((response) => {
            let res = response as IRefreshToken;
            if (res && res.data) {
              localStorage.setItem('idToken', res.data.idToken);
              localStorage.setItem('refreshToken', res.data.refreshToken);
              clearInterval(this.refreshTimer);
              this.refreshTimer = setInterval(() => {
                window.location.reload();
              }, 1000); // Reload automatically every 5 minutes
            } else {
              localStorage.clear();
              this.router.navigate(['/']);
            }
          }).catch(() => {
            localStorage.clear();
            this.router.navigate(['/']);
          })
        }
        throw error;
      }
    }
    else return null;

  }

  private showSiteUnderMaintenanceModal() {
    this.isSiteUnderMaintenance = true;
    this.modalService.closeAllModals();
    this.ngModalService.dismissAll();
    this.modalService.open(SiteUnderMaintenanceModalComponent);
    clearInterval(this.refreshTimer);
    this.refreshTimer = setInterval(() => {
      window.location.reload();
    }, 300000); // Reload automatically every 5 minutes
  }
}
