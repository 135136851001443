import { Component } from '@angular/core';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ModalRef } from '../../../../lib/modal/modal-ref';
import { INetCoreCustomEvents, NetCoreSharedService } from '../../../../services/netcore-shared.service';
import { ISellerOnboardResponse } from '../../../../services/seller-onboard.service';

@Component({
  selector: 'app-seller-onboard-info-modal',
  templateUrl: './seller-onboard-info-modal.component.html',
  styleUrls: ['./seller-onboard-info-modal.component.scss']
})
export class SellerOnboardInfoModalComponent {

  public messageHeader!: string;

  public messageContent!: string;

  public imageUrl!: string;

  public buttonText!: string;

  public infoType!: string;

  public sellerInfo!: ISellerOnboardResponse;

  constructor(
    public modalRef: ModalRef,
    private modalConfig: ModalConfig,
    private readonly netCoreSharedService: NetCoreSharedService,
  ) {
    this.imageUrl = this.modalConfig.data.imageUrl;
    this.messageHeader = this.modalConfig.data.messageHeader;
    this.messageContent = this.modalConfig.data.messageContent;
    this.buttonText = this.modalConfig.data.buttonText;
    this.infoType = this.modalConfig.data.infoType
    this.sellerInfo = this.modalConfig.data.sellerInfo;
  }


  ngOnInit() {
    { }
  }

  createNetCorePayload(): INetCoreCustomEvents {
    return {
      seller_id: this.sellerInfo.sellerInfoId,
      name: this.sellerInfo.sellerName,
      mobile: this.sellerInfo.phoneNumber.replace(/\+/g, ''),
    };
  }

  closeModal() {
    const successfulPopupPayload: INetCoreCustomEvents = this.createNetCorePayload();
    this.netCoreSharedService.dispatchPreScreeningSuccessfullySubmitted(successfulPopupPayload);
    this.modalRef.dataEmit(true);
    this.modalRef.close();
  }
}