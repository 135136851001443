import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClientInterceptor } from '../interceptors/http.interceptor';
import { StorageService } from '../services/storage.service';
import { HotToastModule } from '@ngneat/hot-toast';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ModalRef } from '../lib/modal/modal-ref';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PortalApiService } from '../services/portal-api.service';
import { MatSelectModule } from '@angular/material/select';
import { NgOtpInputModule } from 'ng-otp-input';
import { ModalConfig } from '../lib/modal/modal-config';
import { ModalService } from '../lib/modal/modal.service';
import { ModalModule } from '../lib/modal/modal.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MenuItemApiService } from '../services/menu-item-api.service';
import { S3Service } from '../services/upload.service';
import { CuisineApiService } from '../services/cuisine-api.service';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SiteUnderMaintenanceModalComponent } from './shared/site-under-maintenance-modal/site-under-maintenance-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SellerOnboardInfoModalComponent } from './shared/pop-ups/seller-onboard-info-modal/seller-onboard-info-modal.component';
import { SellerOnboardApiService } from '../services/seller-onboard.service';
import { BrandOnboardApiService } from 'src/services/brand-onboard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    SiteUnderMaintenanceModalComponent,
    SellerOnboardInfoModalComponent,
    PageNotFoundComponent,
  ],
  imports: [
    HotToastModule.forRoot({
      position: 'top-center',
      className: 'hot-toast',
      duration: 1000,
    }),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    GoogleMapsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgOtpInputModule,
    AngularMultiSelectModule,
    NgbModalModule,
    NgSelectModule,
  ],
  providers: [
    SellerOnboardApiService,
    BrandOnboardApiService,
    PortalApiService,
    MenuItemApiService,
    CuisineApiService,
    S3Service,
    StorageService,
    ModalConfig,
    ModalService,
    ModalModule,
    ModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
