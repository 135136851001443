import { Component, OnInit } from '@angular/core';
import { NetCoreSharedService } from '../services/netcore-shared.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Seller-Portal';

  constructor(
    private netCoreSharedService: NetCoreSharedService
  ) { }

  ngOnInit() {
    this.netCoreSharedService.initializeSmartech();
  }
}
