import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

declare const smartech: any;

export enum ScreenName {
  PRESCREENING_PAGE = 'prescreening',
  PERSONAL_DETAILS_PAGE = 'personal_details',
  FSSAI_TERMS_AND_CONDITION_PAGE = 'fssai_terms_and_condition',
  TAX_AND_BANK_DETAILS_PAGE = 'tax_and_bank_details',
  VISUAL_VERIFICATION_PAGE = 'visual_verification',
  ONBOARDING_PAGE = 'onboarding',
}

export interface INetCoreCustomEvents {
  name: string;
  seller_id: string;
  mobile: string;
}

export interface ICuisine {
  cuisineid: string;
  cuisinename: string;
  cuisinealiasname?: string;
  cuisinedescription?: string;
}

export interface IImageUpload {
  file_name: string,
  original_file_name: string,
  photo_url?: {
    url?: string,
    expires_in?: number
  }
}

export interface IPickupLocation {
  location?: {
    type?: string;
    coordinates?: number[];
  }
  address: string;
  landmark: string;
  city: string;
  state: string;
  pinCode: string;
}

export interface INetCoreRegisterSubmitEvents {
  name: string;
  city: string;
  mobile: string;
}

export interface INetCoreRegisterUserPayload {
  'pk^mobile': string;
  NAME: string;
  CITY: string;
}

export interface INetCorePreScreeningSubmitEvents {
  name: string;
  seller_id: string;
  mobile: string;
  is_drafted: boolean;
  is_halal: boolean;
  kitchen_food_type?: Array<string>;
  parent_zone_id?: string;
  sub_zone_id?: string;
  other_area?: string;
  seller_category: Array<string>;
  cuisines: ICuisine[];
  meal_types: Array<string>;
  has_previous_experience: boolean;
  committed_days_per_month: number;
}

export interface INetCorePersonalDetailsSubmitEvents {
  name: string;
  seller_id: string;
  mobile: string;
  age: number;
  title: string;
  alt_phone_number: number;
  email_id: string;
  pick_up_location: IPickupLocation;
  is_drafted: boolean;
}

export interface INetCoreFssaiDetailsSubmitEvents {
  name: string;
  seller_id: string;
  mobile: string;
  fssai_number?: string;
  fssai_certificate_image?: IImageUpload;
  fssai_reference_number?: string;
  is_terms_and_conditions_agreed: boolean;
  is_drafted: boolean;
  fssai_expiry_date: string;
}

export interface INetCoreBankDetailsSubmitEvents {
  name: string;
  seller_id: string;
  mobile: string;
  is_gst_in_available: boolean;
  masked_gst_in_number?: string;
  is_gst_in_verified?: boolean;
  masked_pan_number?: string;
  is_pan_verified?: boolean;
  masked_aadhaar_number?: string;
  is_aadhaar_verified?: boolean;
  bank_betails?: {
    account_holder_name: string;
    account_number: string;
    ifsc_code: string;
    proof_image?: IImageUpload;
  }
  is_drafted: boolean,
  is_provide_later?: boolean,
  aadhaar_number?: string,
  pan_number?: string,
  gst_in_number?: string,
}

export interface INetCoreVisualVerificationSubmitEvents {
  name: string;
  seller_id: string;
  mobile: string;
  is_seller_ready: boolean;
  is_drafted: boolean;
}

export interface INetCoreLoginEvents {
  mobile: string;
  date?: string;
  time?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NetCoreSharedService {
  private smartechCreateKey: string = environment.config['SMARTECH_CREATE_KEY'];

  private smartechRegisterKey: string = environment.config['SMARTECH_REGISTER_KEY'];

  private isSmartechDefined(): boolean {
    return typeof smartech !== 'undefined';
  }

  initializeSmartech() {
    if (this.isSmartechDefined()) {
      smartech('create', this.smartechCreateKey);
      smartech('register', this.smartechRegisterKey);
      smartech('identify', '');
    }
  }

  // User Registration Event
  registerUser(payload: INetCoreRegisterUserPayload) {
    if (this.isSmartechDefined()) {
      smartech('contact', 'LIST IDENTIFIER', payload);
      smartech('identify', payload['pk^mobile']);
    }
  }

  // User Identification Event
  identifyUser(payload: string) {
    if (this.isSmartechDefined()) {
      smartech('identify', payload);
    }
  }

  // Register Page Events
  dispatchRegisterButtonClick(payload: INetCoreRegisterSubmitEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'register_button', payload);
    }
  }

  dispatchWhatsappUpdateClick(payload: INetCoreRegisterSubmitEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'register_whatsapp_update_button', payload);
    }
  }

  dispatchLoginClick(payload: boolean) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'register_popup_login_button', payload);
    }
  }

  // Login Page Events
  dispatchLoginButtonClick(payload: INetCoreLoginEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'login_button', payload);
    }
  }

  dispatchRegisterClick(payload: boolean) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'login_popup_register_button', payload);
    }
  }

  // Logout Event
  dispatchLogoutButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'logout_button', payload);
    }
  }

  // Pre Screening Page Events
  dispatchPreScreeningSelectedCategoryFood(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_selected_category_food', payload);
    }
  }

  dispatchPreScreeningSelectedCategoryProduct(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_selected_category_product', payload);
    }
  }

  dispatchPreScreeningSelectedCategoryBakery(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_selected_category_bakery', payload);
    }
  }

  dispatchPreScreeningSelectedCategoryOthers(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_selected_category_others', payload);
    }
  }

  dispatchPreScreeningFoodTypeVegan(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_food_type_vegan', payload);
    }
  }

  dispatchPreScreeningFoodTypePureVeg(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_food_type_pure_veg', payload);
    }
  }

  dispatchPreScreeningFoodTypeVegAndNonVeg(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_food_type_veg_and_non_veg', payload);
    }
  }

  dispatchPreScreeningFoodTypeVegAndNonVegHalal(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_food_type_veg_and_non_veg_halal', payload);
    }
  }

  dispatchPreScreeningCuisineType(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_cuisine_type', payload);
    }
  }

  dispatchPreScreeningMealTimeBreakfast(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_meal_time_breakfast', payload);
    }
  }

  dispatchPreScreeningMealTimeLunch(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_meal_time_lunch', payload);
    }
  }

  dispatchPreScreeningMealTimeSnacks(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_meal_time_snacks', payload);
    }
  }

  dispatchPreScreeningMealTimeDinner(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_meal_time_dinner', payload);
    }
  }

  dispatchPreScreeningWithExperience(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_seller_with_experience', payload);
    }
  }

  dispatchPreScreeningWithOutExperience(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_seller_without_experience', payload);
    }
  }

  dispatchPreScreeningCommitment(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_seller_commitment', payload);
    }
  }

  dispatchPreScreeningWithFssaiCertificate(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_seller_with_fssai_certificate', payload);
    }
  }

  dispatchPreScreeningWithOutFssaiCertificate(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_seller_without_fssai_certificate', payload);
    }
  }

  dispatchPreScreeningFssaiCertificateNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_seller_fssai_certificate_number', payload);
    }
  }

  dispatchPreScreeningSelectSubZone(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_select_subzone', payload);
    }
  }

  dispatchPreScreeningSelectOtherArea(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_select_other_area', payload);
    }
  }

  dispatchPreScreeningSaveAsDraftButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_save_as_draft_button', payload);
    }
  }

  dispatchPreScreeningSubmitButtonClick(payload: INetCorePreScreeningSubmitEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_submit_button', payload);
    }
  }

  dispatchPreScreeningApplyFssai(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_apply_fssai', payload);
    }
  }

  dispatchPreScreeningSuccessfullySubmitted(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_successfully_submitted_popup', payload);
    }
  }

  // Personal Details Page Events
  dispatchPersonalDetailsTitle(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_title', payload);
    }
  }

  dispatchPersonalDetailsAge(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_age', payload);
    }
  }

  dispatchPersonalDetailsAlternateMobileNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_alternate_mobile_number', payload);
    }
  }

  dispatchPersonalDetailsEmail(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_email', payload);
    }
  }

  dispatchPersonalDetailsPickupLocation(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_pickup_location', payload);
    }
  }

  dispatchPersonalDetailsDetectLocationButton(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_detect_location_button', payload);
    }
  }

  dispatchPersonalDetailsAddress(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_address', payload);
    }
  }

  dispatchPersonalDetailsNearestLandmark(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_nearest_landmark', payload);
    }
  }

  dispatchPersonalDetailsState(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_state', payload);
    }
  }

  dispatchPersonalDetailsDistrict(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_district', payload);
    }
  }

  dispatchPersonalDetailsPinCode(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_pincode', payload);
    }
  }

  dispatchPersonalDetailsSaveAsDraftButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_save_as_draft_button', payload);
    }
  }

  dispatchPersonalDetailsSubmitButtonClick(payload: INetCorePersonalDetailsSubmitEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_submit_button', payload);
    }
  }

  // Fssai, Terms and Conditions Page Events
  dispatchFssaiTermsAndConditionWithFssaiCertificate(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_with_fssai_certificate', payload);
    }
  }

  dispatchFssaiTermsAndConditionWithFssaiCertificateNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_with_fssai_certificate_number', payload);
    }
  }

  dispatchFssaiTermsAndConditionWithFssaiExpiryDate(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_with_fssai_expiry_date', payload);
    }
  }

  dispatchFssaiTermsAndConditionWithFssaiCertificateImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_with_fssai_certificate_image', payload);
    }
  }

  dispatchFssaiTermsAndConditionWithoutFssaiCertificate(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_without_fssai_certificate', payload);
    }
  }

  dispatchFssaiTermsAndConditionWithFssaiReferenceID(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_with_fssai_reference_id', payload);
    }
  }

  dispatchFssaiTermsAndConditionApplyFssai(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_apply_fssai', payload);
    }
  }

  dispatchFssaiTermsAndConditionNeedHelp(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_need_help', payload);
    }
  }

  dispatchFssaiTermsAndConditionAgreeCheck(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_agree', payload);
    }
  }

  dispatchFssaiTermsAndConditionSaveAsDraftButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_save_as_draft_button', payload);
    }
  }

  dispatchFssaiTermsAndConditionSubmitButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_submit_button', payload);
    }
  }

  // Tax and Bank Details Page Events
  dispatchTaxAndBankDetailsWithGstin(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_with_gstin', payload);
    }
  }

  dispatchTaxAndBankDetailsWithGstinNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_with_gstin_number', payload);
    }
  }

  dispatchTaxAndBankDetailsWithGstinNumberVerifyButton(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_with_gstin_number_verify_button', payload);
    }
  }

  dispatchTaxAndBankDetailsWithOutGstin(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_without_gstin', payload);
    }
  }

  dispatchTaxAndBankDetailsWithAadharCard(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_with_aadharcard', payload);
    }
  }

  dispatchTaxAndBankDetailsWithAadharNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_with_aadhar_number', payload);
    }
  }

  dispatchTaxAndBankDetailsWithAadharCardVerifyButton(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_with_aadharcard_verify_button', payload);
    }
  }

  dispatchTaxAndBankDetailsWithPanCard(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_pancard', payload);
    }
  }

  dispatchTaxAndBankDetailsWithPanNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_pan_number', payload);
    }
  }

  dispatchTaxAndBankDetailsWithPanCardVerifyButton(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_pancard_verify_button', payload);
    }
  }

  dispatchTaxAndBankDetailsWithOutIdentification(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_without_identification', payload);
    }
  }

  dispatchTaxAndBankDetailsWithAccHolderName(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_bank_acc_holder_name', payload);
    }
  }

  dispatchTaxAndBankDetailsWithAccNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_bank_acc_number', payload);
    }
  }

  dispatchTaxAndBankDetailsWithAccConfirmNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_bank_confirm_acc_number', payload);
    }
  }

  dispatchTaxAndBankDetailsWithBankIFSCNumber(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_bank_ifsc_number', payload);
    }
  }

  dispatchTaxAndBankDetailsWithBankFindIFSCCode(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_bank_find_ifsc_code', payload);
    }
  }

  dispatchTaxAndBankDetailsWithBankImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_with_bank_image', payload);
    }
  }

  dispatchTaxAndBankDetailsProvideLater(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_deatils_provide_later', payload);
    }
  }

  dispatchTaxAndBankDetailsApplyFssai(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_apply_fssai', payload);
    }
  }

  dispatchTaxAndBankDetailsNeedHelp(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_need_help', payload);
    }
  }

  dispatchTaxAndBankDetailsSaveAsDraftButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_save_as_draft_button', payload);
    }
  }

  dispatchTaxAndBankDetailsSubmitButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_submit_button', payload);
    }
  }

  // Visual Verification Page Events
  dispatchVisualVerificationUploadImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_image_upload', payload);
    }
  }

  dispatchVisualVerificationConfirmationCheck(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_confirmation_checkbox', payload);
    }
  }

  dispatchVisualVerificationApplyFssai(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_apply_fssai', payload);
    }
  }

  dispatchVisualVerificationNeedHelp(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_need_help', payload);
    }
  }

  dispatchVisualVerificationSaveAsDraftButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_save_as_draft_button', payload);
    }
  }

  dispatchVisualVerificationSubmitButtonClick(payload: INetCoreVisualVerificationSubmitEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_submit_button', payload);
    }
  }

  dispatchVisualVerificationSuccessfullySubmitted(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_successfully_submitted_popup', payload);
    }
  }

  // Onboarding Page Events
  dispatchOnboardingUploadFoodSellerProfileImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_profile_image', payload);
    }
  }

  dispatchOnboardingUploadFoodSellerCoverImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_cover_image', payload);
    }
  }

  dispatchOnboardingFoodSellerKitchenName(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_kitchen_name', payload);
    }
  }

  dispatchOnboardingFoodSellerKitchenDescription(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_kitchen_description', payload);
    }
  }

  dispatchOnboardingFoodSellerCuisines(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_cuisines', payload);
    }
  }

  dispatchOnboardingFoodSellerKitchenTypeVegan(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_kitchen_type_vegan', payload);
    }
  }

  dispatchOnboardingFoodSellerKitchenTypePureVeg(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_kitchen_type_pure_veg', payload);
    }
  }

  dispatchOnboardingFoodSellerKitchenTypeVegAndNonVeg(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_kitchen_type_veg_and_non_veg', payload);
    }
  }

  dispatchOnboardingFoodSellerKitchenTypeVegAndNonVegHalal(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_food_seller_kitchen_type_veg_and_non_veg_halal', payload);
    }
  }

  dispatchOnboardingProductSellerBrandName(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_product_seller_brand_name', payload);
    }
  }

  dispatchOnboardingProductSellerCategory(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_product_seller_category', payload);
    }
  }

  dispatchOnboardingUploadProductSellerProfileImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_product_seller_profile_image', payload);
    }
  }

  dispatchOnboardingUploadProductSellerCoverImage(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_product_seller_cover_image', payload);
    }
  }

  dispatchOnboardingSaveAsDraftButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_save_as_draft_button', payload);
    }
  }

  dispatchOnboardingSubmitButtonClick(payload: INetCoreCustomEvents) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_submit_button', payload);
    }
  }

  // NetCore Nudges

  // Prescreening Page Nudge
  netCoreNudgeOnPrescreeningPageLoad(screen_name: string) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'prescreening_page_load', { screen_name });
    }
  }

  // Personal Details Page Nudge
  netCoreNudgeOnPersonalDetailsPageLoad(screen_name: string) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'personal_details_page_load', { screen_name });
    }
  }

  // Fssai, Terms and Conditions Page Nudge
  netCoreNudgeOnFssaiTermsAndConditionPageLoad(screen_name: string) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'fssai_terms_and_condition_page_load', { screen_name });
    }
  }

  // Tax and Bank Details Page Nudge
  netCoreNudgeOnTaxAndBankDetailsPageLoad(screen_name: string) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'tax_and_bank_details_page_load', { screen_name });
    }
  }

  // Visual Verification Page Nudge
  netCoreNudgeOnVisualVerificationPageLoad(screen_name: string) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'visual_verification_page_load', { screen_name });
    }
  }

  // Onboarding Page Nudge
  netCoreNudgeOnOnboardingPageLoad(screen_name: string) {
    if (this.isSmartechDefined()) {
      smartech('dispatch', 'onboarding_page_load', { screen_name });
    }
  }
}
