export const profileStatus = [{
  label: 'Pending',
  value: 'pending',
},
{
  label: 'In-Review',
  value: 'inReview',
},
{
  label: 'Approved',
  value: 'approved',
},
{
  label: 'Rejected',
  value: 'rejected',
},
];

export enum ProfileApprovalStatusEnum {
  PENDING = 'pending',
  IN_REVIEW = 'inReview',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}